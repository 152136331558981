import { CeresFeaturesConfig } from './interfaces/ceres-features-config.interface';

const FEATURES: CeresFeaturesConfig = {
  timeManagement: {
    enabled: true,
    timeSheets: true,
    transactionalTimeTracking: true,
    capacity: true,
    absences: true,
    workload: true
  },
  businessPartner: {
    enabled: true
  },
  sales: {
    enabled: true,
    businessOpportunities: true,
    salesActivities: true,
    salesFunnel: true,
    offers: true,
    offersV2: true
  },
  projects: {
    enabled: true,
    copyExistingProject: true,
    orcChecker: false,
    inheritance: true,
    groups: true,
    charging: true,
    nrsView: true,
    preStaffing: true
  },
  reporting: {
    enabled: true,
    dataQuality: true,
    pulseDashboard: true,
    costReport: true,
    workingHoursReport: true,
    gmsReportingDemo: false,
    productivityReport: true
  },
  employees: {
    enabled: true,
    skills: true,
    multiRole: true
  },
  faq: {
    enabled: true
  },
  ticketTool: {
    enabled: true
  },
  administration: {
    enabled: true,
    servicePackages: true,
    departments: true,
    serviceProducts: true,
    orgIds: true,
    translations: true,
    skillMatrix: true,
    juno: true,
    customerPspElements: false
  },
  services: {
    enabled: true
  },
  global: {
    currencyConversion: true,
    darkMode: true,
  }
};

export default FEATURES;
